import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import { Layout, SEO, ArticleCard } from 'components';
import { Stack, List, Container } from '@tymate/margaret';
import { SectionImage, PageTitle } from 'ui';

const PaginationLink = styled(Link)`
  color: ${({ theme }) => theme.secondary};
  text-decoration: none;
`;

const ArticlesPage = ({ data, pageContext }) => {
  const { page } = pageContext;

  return (
    <Layout>
      <SEO
        title={`${data?.datoCmsBlogPage?.seo?.title} - Page ${page}`}
        description={`${data?.datoCmsBlogPage?.seo?.description} – Page ${page}`}
      />
      <Container variant="main">
        <PageTitle>{`${data?.datoCmsBlogPage?.title} - Page ${page}`}</PageTitle>

        <Stack direction={{ default: 'column', desktop: 'row' }} gutterSize={2}>
          <List direction="column">
            {(data?.allDatoCmsArticle?.edges || []).map(({ node }) => (
              <ArticleCard article={node} key={node.id} />
            ))}
          </List>
          <SectionImage>
            <img
              src={data?.datoCmsBlogPage?.image?.url}
              alt={data?.datoCmsBlogPage?.image?.alt}
            />
          </SectionImage>
        </Stack>

        <Stack direction="row" size="full" alignX="space-between">
          {data?.allDatoCmsArticle?.pageInfo?.hasNextPage ? (
            <PaginationLink to={`/blog/pages/${page + 1}`}>
              Articles plus anciens
            </PaginationLink>
          ) : (
            <span />
          )}
          {page > 1 && (
            <PaginationLink
              to={page === 2 ? '/blog' : `/blog/pages/${page - 1}`}
            >
              Articles plus récents
            </PaginationLink>
          )}
        </Stack>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ArticlesPage($skip: Int, $limit: Int) {
    datoCmsBlogPage {
      title
      image {
        url
        alt
      }
      seo {
        title
        description
      }
    }
    allDatoCmsArticle(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: meta___publishedAt }
    ) {
      edges {
        node {
          id
          slug
          title
          description
          content
          meta {
            publishedAt
          }
          skill {
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export default ArticlesPage;
